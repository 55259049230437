<template>
    <ForgotPassword/>
</template>

<script>
import ForgotPassword from '../../components/auth/ForgotPassword'
export default {

    components: {
        ForgotPassword
    }
}
</script>

<style>

</style>
