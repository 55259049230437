<template>
<div>
    <div class="login-div forgot" style="background-size:contain">
        

        <div class="row">
            <div class="col-lg-8 col-12">

            </div>
            <div class="col-lg-4 col-12 login-container text-center">

                <img src="../../assets/images/logo.png" loading="lazy" height="70" class="mt-60" @click="sendToMarket">
                <div class="form-div">

                    <form action="" class="form text-center">
                        <h4 class="mt-2 head-font">{{tran.forgotPassword}}</h4>
                        <p class="mt-4 mb-4">{{tran.enterEmail}}</p>
                        <div class="form-group">
                            <label class="form-label" @click.prevent="focusOnInput($event)" for="first">{{tran.userEmail}}</label>
                            <input  v-model="formdata.email" class="form-input" type="email"  autocomplete="off" @keydown.enter.prevent="checkEnterButton"/>
                           </div>

                            <div class="form-group">
                                <button type="button" class="submit-btn mt-2" :class="{'fade-elem' : loader}" @click.prevent="sendForgotPassword"> {{ loader ? '' :  tran.resetLogin }}  <span class="loader mt-1" v-if="loader"></span></button>
                            </div>

                            <h6 class="mt-4 underline" @click="sendToLogin">{{tran.login}}</h6>
                    </form>

                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import trans from '../../../translations'
import {userNotification} from "../dashboard/mixins/notifyUser.js";
import {
    mapState
} from "vuex";


export default {
    
    mixins:[userNotification],
    data() {

        return {

            tran: {}
        }
    },
    computed: {
        ...mapState({

            formdata: state => state.auth.forgotPassword,
            loader: state => state.auth.loader,
        }),
    },

    methods: {
   sendToMarket(){
            this.$router.push('/market')
        },


        sendForgotPassword() {

            this.$store.dispatch('auth/sendForgotPassword');
        },
        sendToLogin() {

            this.$router.push('/login')
        },

        focusOnInput(e) {

            e.target.parentNode.querySelector('.form-input').focus();
        },
     checkEnterButton() {
            if (event.keyCode === 13) {
                this.sendForgotPassword();
            }
        },
    },
    mounted() {

    }
}
</script>

<style>

</style>
